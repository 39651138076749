const bundle = (state = [], action) => {
    switch (action.type) {
        case "GET_BUNDLE":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

const bundleTotalPage = (state = 0, action) => {
    switch (action.type) {
        case "GET_TOTAL_PAGE_BUNDLE":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

const bundleList = (state = [], action) => {
    switch (action.type) {
        case "GET_BUNDLE_LIST":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

const bundleListTotalPage = (state = 0, action) => {
    switch (action.type) {
        case "GET_TOTAL_PAGE_BUNDLE_LIST":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

const bundleClinic = (state = [], action) => {
    switch (action.type) {
        case "GET_BUNDLE_CLINIC":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

const bundleClinicTotalPage = (state = 0, action) => {
    switch (action.type) {
        case "GET_TOTAL_PAGE_BUNDLE_CLINIC":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

const bundleConsumables = (state = [], action) => {
    switch (action.type) {
        case "GET_BUNDLE_CONSUMABLES":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

const bundleConsumablesTotalPage = (state = 0, action) => {
    switch (action.type) {
        case "GET_TOTAL_PAGE_BUNDLE_CONSUMABLES":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

const bundleService = (state = [], action) => {
    switch (action.type) {
        case "GET_BUNDLE_SERVICE":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

const bundleServiceTotalPage = (state = 0, action) => {
    switch (action.type) {
        case "GET_TOTAL_PAGE_BUNDLE_SERVICE":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

const bundleMedicine = (state = [], action) => {
    switch (action.type) {
        case "GET_BUNDLE_MEDICINE":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

const bundleMedicineTotalPage = (state = 0, action) => {
    switch (action.type) {
        case "GET_TOTAL_PAGE_BUNDLE_MEDICINE":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

export { bundle, bundleTotalPage, bundleList, bundleListTotalPage, bundleClinic, bundleClinicTotalPage, bundleConsumables, bundleConsumablesTotalPage, bundleService, bundleServiceTotalPage, bundleMedicine, bundleMedicineTotalPage } ;