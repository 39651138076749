const accounting = (state = [], action) => {
    switch (action.type) {
        case "GET_ACCOUNTING":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

const accountingTotalPage = (state = 0, action) => {
    switch (action.type) {
        case "GET_TOTAL_PAGE_ACCOUNTING":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

const accountingCategory = (state = [], action) => {
    switch (action.type) {
        case "GET_ACCOUNTING_CATEGORY":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

const accountingCategoryTotalPage = (state = 0, action) => {
    switch (action.type) {
        case "GET_TOTAL_PAGE_ACCOUNTING_CATEGORY":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

const accountingTransactions = (state = [], action) => {
    switch (action.type) {
        case "GET_ACCOUNTING_TRANSACTIONS":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

const accountingTransactionsTotalPage = (state = 0, action) => {
    switch (action.type) {
        case "GET_TOTAL_PAGE_ACCOUNTING_TRANSACTIONS":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

const accountingTarget = (state = [], action) => {
    switch (action.type) {
        case "GET_ACCOUNTING_TARGET":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

const accountingTargetTotalPage = (state = 0, action) => {
    switch (action.type) {
        case "GET_TOTAL_PAGE_ACCOUNTING_TARGET":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}


const accountingReconcile = (state = [], action) => {
    switch (action.type) {
        case "GET_ACCOUNTING_RECONCILE":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

const accountingReconcileTotalPage = (state = 0, action) => {
    switch (action.type) {
        case "GET_TOTAL_PAGE_ACCOUNTING_RECONCILE":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

export { accounting, accountingTotalPage, accountingCategory, accountingCategoryTotalPage, accountingTransactions, accountingTransactionsTotalPage, accountingTarget, accountingTargetTotalPage, accountingReconcile, accountingReconcileTotalPage } ;