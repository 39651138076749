const recordDocumentTotalPage = (state = 0, action) => {
    switch (action.type) {
        case "GET_TOTAL_PAGE_RECORDDOCUMENT":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

const allRecordDocumentByPatient = (state = [], action) => {
    switch (action.type) {
        case "GET_ALL_RECORDDOCUMENT_BY_PATIENT":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

export { recordDocumentTotalPage, allRecordDocumentByPatient } ;