const equipment = (state = [], action) => {
    switch (action.type) {
        case "GET_EQUIPMENT":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

const equipmentTotalPage = (state = 0, action) => {
    switch (action.type) {
        case "GET_TOTAL_PAGE_EQUIPMENT":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

const equipmentClinic = (state = [], action) => {
    switch (action.type) {
        case "GET_EQUIPMENT_CLINIC":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

const equipmentClinicTotalPage = (state = 0, action) => {
    switch (action.type) {
        case "GET_TOTAL_PAGE_EQUIPMENT_CLINIC":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

export { equipment, equipmentTotalPage, equipmentClinic, equipmentClinicTotalPage } ;